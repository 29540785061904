<template>
<div>Home</div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      },
      set (payload) {
        this.$store.commit('setUser', payload)
      }
    }
  },
  data () {
    return {
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon"
    this.$stat.log({ page: 'home' })
    if (this.$config.BUILD === 'full') {
      this.$router.replace({ name: 'Start' })
    }
    if (this.$config.BUILD === 'external' || this.$config.BUILD === 'externalkpmgarc') {
      if (this.user.homepage) {
        if (this.user.homepage === 'ImpactPoliciesDashboard') {
          this.$router.replace({ name: 'CPRD' })
        } else {
          this.$router.replace({ name: this.user.homepage })
        }
      } else {
        this.$router.replace({ name: 'Start' })
      }
    }
  },
  methods: {
  }
}
</script>
